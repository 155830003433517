import React, { useEffect } from "react";
import AOS from "aos";
import 'aos/dist/aos.css';
import "../css/section8.css"
import "../css/animations/animation8.css"

import libro3 from "../assets/PAGINA 1/LIBRO.png"
import Button from '@mui/material/Button';
import {ThemeProvider } from '@mui/material/styles';
import {sanar} from "../components/section6.jsx"
import {theme} from "../components/section6.jsx"

import { Container, Row, Col } from "react-bootstrap";


const Section8 = ()=>{
  useEffect(() => {
    AOS.init({duration: 2000, disable: "mobile"})
  }, []);

    return (
      <Container fluid className="fondo8">
        <ul className="background">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
        <Row className="container-text8 justify-content-center">
          <Row><h3 data-aos="zoom-in-down" className="title8-1">¿Qué requieren las personas con cáncer?</h3> </Row>
          <Row>
            <Col xl={5}></Col>
            <Col xl={2} data-aos="flip-up">
              <img className="libro8" src={libro3} alt="Imagen Libro"/>
            </Col>
            <Col xl={5}></Col>
          </Row>
          <Row>
            <Col xl={2}></Col>
            <Col xl={8} xs={12} data-aos="fade-left">
              <p className="text8-1">
                Para poder crear un nuevo concepto de la persona diagnosticada, 
                una nueva forma de amarse y amarla, para encontrarse en aquel lugar donde aún quizás no te encuentras, 
                para que encuentres el sentido de la situación, para que dejes de preguntarte los ¿Por qué? y 
                empieces a crear desde esta nueva situación una vida... y no una vida basada en el miedo, 
                sino una vida, basada en la vida.
              </p>
            </Col>
            <Col xl={1}></Col>
            <Col xl={1} data-aos="zoom-in">
              <ThemeProvider theme={theme}>
                <a className="buy-book" target="_blank" rel="noreferrer" href="https://docs.google.com/forms/d/e/1FAIpQLSctjLIGM0xyB_92wZICXEDeScIHAhdHE8mV6C49NOl4ZMIvUA/viewform"><Button className="btn8" color="neutral" variant="contained" sx={sanar}>QUIERO EL LIBRO</Button></a>
              </ThemeProvider>
            </Col>
          </Row>
        </Row>
      </Container>
    )
}


export default Section8;