import React, { useEffect } from "react";
import "../css/section6.css"
import "../css/animations/animation6.css"
import AOS from "aos";
import 'aos/dist/aos.css';

import { createTheme } from '@mui/material/styles';

import foto1 from "../assets/PAGINA 6/FOTO 1 PAG 12.png"
import foto2 from "../assets/PAGINA 6/FOTO 2 PAG 12.png"
import foto3 from "../assets/PAGINA 6/FOTO 3 PAG 12.png"
import foto4 from "../assets/PAGINA 6/FOTO 4 PAG 12.png"
import foto5 from "../assets/PAGINA 6/FOTO 5 PAG 12.png"
import { Container, Row, Col } from "react-bootstrap";

export const theme = createTheme({
    palette: {
      neutral: {
        main: '#ffcc80',
        contrastText: '#fff',
      },
    },
});

export const sanar = {
  border:'solid 1px black 0.1',
  borderRadius:'120px',
  padding:'20px 20px 20px 20px', 
  '&:hover': {
    bgcolor:'#ffdd72',
    boxShadow: '0px 6px 12px rgba(27, 27, 27, 0.701)'
  }
}

const Section6 = ()=>{
  useEffect(() => {
    AOS.init({duration: 2000, disable: "mobile"})
  }, []);

    return (
      <Container fluid className="fondo6">
        <Row className="container-text6 justify-content-center">
          <Row><Col xs={12} data-aos="zoom-in-down"><h3 className="title6">¿Qué requieren las personas con cáncer?</h3></Col></Row>
          <Row>
            <Col xl={{span: 4}} xs={12} data-aos="fade-up" data-aos-duration="3000" className="container-son6">
              <img src={foto1} alt="" className="img6a" />
              <p className="texto6">Un amor <br/>permanente y <br/>cercano.</p>
            </Col>
            <Col xl={{span: 4}} className="container-son6"></Col>
            <Col xl={{span: 4}} xs={12} data-aos="fade-up" data-aos-duration="3000" className="container-son6">
              <img src={foto3} alt="" className="img6a"/>
              <p className="texto6">Médicos profesionales, capacitados continuamente.</p>      
            </Col>
          </Row>
          <Row>
            <Col xl={{span: 4}} xs={12} data-aos="fade-up" data-aos-duration="3000" className="container-son6">
              <img src={foto4} alt="" className="img6a" />
              <p className="texto6">Psicólogos, coaches o<br/> mentores.</p>
            </Col>
            <Col xl={{span: 4}} xs={12} data-aos="fade-up" data-aos-duration="3000" className="container-son6">
              <img src={foto2} alt="" className="img6a" />
              <p className="texto6">Compañía preparada<br/> o con la capacidad<br/> para afrontar cada<br/> paso.</p>
            </Col>
            <Col xl={{span: 4}} xs={12} data-aos="fade-up" data-aos-duration="3000" className="container-son6">
              <img src={foto5} alt="" className="img6a" />
              <p className="texto6">
                Audios, videos, libros, terapias...
              </p>
            </Col>
          </Row>
        </Row>
      </Container>
    )
}


export default Section6;