import React, { useEffect } from "react";
import "../css/section2.css"
import "../css/animations/animation2.css"
import AOS from "aos";
import 'aos/dist/aos.css';

import prueba from "../assets/PAGINA 2/FONDO PRUEBA.png"
import { Container, Row, Col } from "react-bootstrap";

const Section2 = ()=>{
  useEffect(() => {
    AOS.init({duration: 2000, disable: "mobile"})
  }, []);

    return (
      <Container fluid className="fondo2">
        <Row className="justify-content-center container-title2">
          <Col xl={12} data-aos="fade-down"><h2 className="title2">¿Quién soy?</h2></Col>
        </Row>
        <Row className="container-text2 justify-content-center">
          <Col xl={5} xs={12} data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="2000" className="prueba"><img className="img-prueba" src={prueba} alt="Family" /></Col>
          <Col xl={2} xs={12} className="text-align-center"><span className="linea-horizontal2"><hr/></span></Col>
          <Col xl={5} xs={12} data-aos="zoom-out-left">
            <p className="texto2">
              Soy Janeth Once Ortiz, a los 35 años la cabeza de mi fémur
              derecho se rompió sin razón aparente, el dolor físico era
              indescriptible, sin embargo, más profundo era el dolor que sentía
              al saber luego que era porque tenía cáncer de mama y
              metástasis ósea, además comprometidos otros órganos, las
              células de mi cuerpo se estaban degenerando, tenía pocas
              probabilidades de vivir.
            </p>
          </Col>
        </Row>
      </Container>
    )
}

export default Section2;