import React, { useEffect } from "react";
import AOS from "aos";
import 'aos/dist/aos.css';
import "../css/section11.css"
import "../css/animations/animation11.css"

import book from "../assets/PAGINA 11/LIBRO.png"
import logo2 from "../assets/PAGINA 11/LOGOTIPO3-1 PNG.png"
import rrss2 from "../assets/PAGINA 11/rrss1.png"

import Button from '@mui/material/Button';
import {ThemeProvider } from '@mui/material/styles';
import {theme} from "../components/section6.jsx"
import { Container, Row, Col } from "react-bootstrap";


export const quimio = {
  border:'solid 1px black 0.1',
  borderRadius:'375px',
  padding:'36px', 
  '&:hover': {
    bgcolor:'#ffdd72',
    boxShadow: '0px 6px 12px rgba(27, 27, 27, 0.701)'
  }
}


const Section11 = ()=>{
  useEffect(() => {
    AOS.init({duration: 2000, disable: "mobile"})
  }, []);

    return (
      <Container fluid className="fondo11">
        <div className="custom-shape-divider-top-section11">
          <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
              <rect x="1200" height="3.6"></rect>
              <rect height="3.6"></rect>
              <path d="M0,0V3.6H580.08c11,0,19.92,5.09,19.92,13.2,0-8.14,8.88-13.2,19.92-13.2H1200V0Z" className="shape-fill"></path>
          </svg>
        </div>
        <Row className="container-text11 justify-content-center">
          <Row className="container11-fila1">
            <Col xl={2}></Col>
            <Col xl={{span: 5, order: 1}} xs={{span: 12, order: 2}} className="container-text11-1" data-aos="zoom-out-up"><h3 className="text11-1">Lo logré y tú también<br/> lo vas a lograr. <br/> Con amor</h3></Col>
            <Col xl={{span: 5, order: 2}} xs={{span: 12, order: 1}} data-aos="zoom-in-left" className="container-btn11">
              <ThemeProvider theme={theme}>
                <a className="buy-book" target="_blank" rel="noreferrer" href="https://docs.google.com/forms/d/e/1FAIpQLSctjLIGM0xyB_92wZICXEDeScIHAhdHE8mV6C49NOl4ZMIvUA/viewform"><Button className="btn11" color="neutral" variant="contained" sx={quimio}>QUIERO EL LIBRO</Button></a>
              </ThemeProvider>
            </Col>
          </Row>
          <Row className="align-items">
            <Col xl={{span: 3, order: 1}}></Col>
            <Col xl={{span: 3, order: 2}} xs={{span: 12, order: 1}} data-aos="zoom-out-up" className="container-logo11">
              <img className="logo11" src={logo2} alt="Logo"/>
            </Col>
            <Col xl={{span: 2, order: 3}}></Col>
            <Col xl={{span: 4, order: 4}} xs={{span: 12, order: 2}} data-aos="flip-up"><img className="book" src={book} alt="Foto del libro de Janeth"/></Col>
          </Row>
          <Row className="container11-fila3">
            <Col xs={12} data-aos="flip-down" data-aos-offset="100"><img className="rrss11" src={rrss2} alt="Redes Sociales"/></Col>
          </Row>
        </Row>
      </Container>
    )
}


export default Section11;