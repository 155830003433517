import React, { useEffect } from "react";
import AOS from "aos";
import 'aos/dist/aos.css';
import "../css/section10.css"
import "../css/animations/animation10.css"
import logo3 from "../assets/PAGINA 10/LOGOTIPO PNG-mod.png"
import foto from '../assets/PAGINA 10/Copia de DSC03802.JPG';

import { Container, Row, Col } from "react-bootstrap";

const Section10 = ()=>{
    useEffect(() => {
        AOS.init({duration: 2000, disable: "mobile"})
    }, []);

    return (
        <Container fluid className="fondo10">
            <div className="ribbon-section10"></div>
            <Row className="container-text10 justify-content-center">
                <Row><h3 data-aos="zoom-out" className="title10-1">SOBRE MÍ</h3> </Row>
                <Row className="container-10-img-text">
                    <Col xl={4} className="container10-img-1" data-aos="zoom-in-top">
                        <img src={foto} alt="Janeth con mano levantada" className="foto10-1"/>
                    </Col>
                    <Col xl={6}>
                        <p data-aos="fade-right" data-aos-easing="ease-in-sine" className="text10-1">
                            Etiquetas profesionales de: Master, Ingeniera, Life coach, Speaker, Consultora de empresas. 
                            <br/>Lo que soy: Resiliente, Optimista, Creadora, Imparable, Una mujer de fe y de amor.
                        </p>
                    </Col>
                    <Col xl={2}></Col>
                </Row>
                <Row className="container10-logo">
                    <Col xl={4}></Col>
                    <Col xl={2} data-aos="fade-up" data-aos-duration="3000"><img className="logo10" src={logo3} alt="Logo"/></Col>
                    <Col xl={6}></Col>
                </Row>
            </Row>
            <div className="ribbon-section10-2"></div>
        </Container>
    )
}


export default Section10;