import React, { useEffect } from "react";
import "../css/section3.css"
import "../css/animations/animation3.css"
import cadenas from "../assets/PAGINA 3/SENTENCIADA.png"
import AOS from "aos";
import 'aos/dist/aos.css';

import { Container, Row, Col } from "react-bootstrap";


const Section3 = ()=>{
  useEffect(() => {
    AOS.init({duration: 2000, disable: "mobile"})
  }, []);

    return (
      <Container fluid className="fondo2">
        <Row className="justify-content-center container-title2">
          <Col xl={12} data-aos="fade-down"><h2 className="title3">¿Sentenciada?</h2></Col>
        </Row>
        <Row className="container-text2 justify-content-center">
          <Col xl={{span: 5, order: 3}} xs={12} data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="2000" className="prueba"><img className="img-prueba" src={cadenas} alt="Cadenas" /></Col>
          <Col xl={{span: 2, order: 2}} xs={12} className="text-align-center"><span className="linea-horizontal3"><hr/></span></Col>
          <Col xl={{span: 5, order: 1}} xs={12} data-aos="zoom-out-rigth">
            <p className="texto3">
              Para muchos estaba sentenciada, tan solo, mi fe, 
              fue inquebrantable y hoy estoy recuperada, mi cáncer está inactivo, camino y puedo seguir 
              siendo mujer, madre, hija, esposa y profesional, creando desde el amor momentos que valgan 
              la pena para mi y para quienes comparto.
            </p>
          </Col>
        </Row>
      </Container>
    )
}

export default Section3;