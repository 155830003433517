import React, { useEffect } from "react";
import "../css/section1.css"
import "../css/animations/animation.css"

import AOS from "aos";
import 'aos/dist/aos.css';
import logo from "../assets/PAGINA 1/LOGOTIPO PNG.png"
import libro from "../assets/PAGINA 1/LIBRO.png"
import Typewriter from "typewriter-effect";
import Button from '@mui/material/Button';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from "../components/section6.jsx";
import { sanar } from "../components/section6.jsx";

import { Container, Row, Col } from "react-bootstrap";


const Section1 = ()=>{
  useEffect(() => {
    AOS.init({duration: 2000, disable: "mobile"})
  }, []);

    return (
        <Container fluid className="fondo">
          <Row className="title1-container">
            <Col xl={{span: 5, order: 1}} xs={{span: 12, order: 2}} data-aos="fade-down" data-aos-delay="300" className="container-logo"><img className="logo" src={logo} alt="Logo"/></Col>
            <Col xl={{span: 4, order: 2}} xs={{span: 12, order: 1}} data-aos="fade-zoom-in" className="container-text">
              <h1 className="canc">CÁNCER</h1>
              <h2 className="diag"><Typewriter options={{
                autoStart: true,
                loop: true,
                delay: 50,
                strings: "El diagnóstico no es el resultado de tu vida."
                
              }} /></h2>
            </Col>
            <Col xl={{span: 3, order: 3}}></Col>
          </Row>
          <Row className="elements1-container">
            <Col xl={{span: 2, order: 1}}></Col>
            <Col xl={{span: 4, order: 2}} xs={{span: 6, order: 1}} className="container1-button" data-aos="zoom-in">
              <ThemeProvider theme={theme}>
                <a className="buy-book" target="_blank" rel="noreferrer" href="https://docs.google.com/forms/d/e/1FAIpQLSctjLIGM0xyB_92wZICXEDeScIHAhdHE8mV6C49NOl4ZMIvUA/viewform"><Button className="btn1" color="neutral" variant="contained" sx={sanar}>QUIERO EL LIBRO</Button></a>
              </ThemeProvider>
            </Col>
            <Col xl={{span: 6, order: 3}} xs={{span: 6, order: 2}} data-aos="fade-left" data-aos-delay="500" className="container-libro"><img className="libro" src={libro} alt="Imagen Libro"/></Col>
          </Row>
          <div className="custom-shape-divider-bottom-section1">
              <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                  <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
              </svg>
          </div>
      </Container>
    )
}

export default Section1;