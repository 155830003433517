import React, { useEffect } from "react";
import "../css/section7.css"
import "../css/animations/animation7.css"
import AOS from "aos";
import 'aos/dist/aos.css';
import planta from "../assets/PAGINA 5/planta.png"

import { Container, Row, Col } from "react-bootstrap";


const Section7 = ()=>{
  useEffect(() => {
    AOS.init({duration: 2000, disable: "mobile"})
  }, []);

    return (
      <Container fluid className="fondo7">
        <div className="ribbon"></div>
        <Row className="container-text7">
          <Col xs={12} data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine"><h3 className="title7-1">Adicional los pacientes<br/> oncológicos</h3></Col>
          <Row className="container7-text2">
            <Col xl={6} xs={12} data-aos="flip-right" className="container-title7-2">
              <h3 className="title7-2">NECESITAN:</h3>
              <img src={planta} alt="Planta" className="foto7-1" />
            </Col>
            <Col xl={6} data-aos="zoom-out-left">
              <div className="rect1">
                <h3 className="text7-1">PerdonarSE</h3> 
              </div>
              <div className="rect1">
                <h3 className="text7-1">AceptarSE</h3> 
              </div>
              <div className="rect1">
                <h3 className="text7-1">FE inquebrantable</h3> 
              </div>
              <div className="rect1">
                <h3 className="text7-1">CAMBIAR hábitos</h3> 
              </div>
              <div className="rect1">
                <h3 className="text7-1">SER protagonistas</h3> 
              </div>
            </Col>
          </Row>    
        </Row>
      </Container>
    )
}


export default Section7;