import React, { useEffect } from "react";
import AOS from "aos";
import 'aos/dist/aos.css';
import "../css/section9.css"
import "../css/animations/animation9.css"

import rrss from "../assets/PAGINA 9/rrss1.png"

import { Container, Row, Col } from "react-bootstrap";

const Section9 = ()=>{
  useEffect(() => {
    AOS.init({duration: 2000, disable: "mobile"})
  }, []);

    return (
      <Container fluid className="fondo9">
        <div className="ribbon-section9"></div>
        <Row className="container-text9 justify-content-center">
          <Row><h3 data-aos="flip-up" className="title9-1">¿Aún no estás seguro/segura?</h3> </Row>
          <Row>
            <Col xl={2}></Col>
            <Col xl={8} data-aos="zoom-in-down">
              <p className="text9-1">
                Sí, con el cáncer ocurre esto, de no saber que hacer, 
                no saber hacia donde ir, no saber nada.<br/><br/> Sin embargo, te recuerdo que aún 
                lo tienes todo, cree...<br/><br/> Que tu fe, sea más grande que tus miedos.
                Sólo quiero compartir contigo una gotita de amor, para ti o para tu 
                familiar o amigo, cada gota cuenta. <br/>
                Hoy es el momento de tener una fe inquebrantable.<br/><br/>
                Espero me puedas leer, si aún no te decides, me puedes encontrar en:
              </p>
            </Col>
            <Col xl={2}></Col>
          </Row>
          <Row>
            <Col xl={5}></Col>
            <Col xl={2}><img data-aos="flip-down" className="rrss" src={rrss} alt="Redes sociales Janeth"/></Col>
            <Col xl={5}></Col>
          </Row>
        </Row>
        <div className="ribbon-section9-2"></div>
      </Container>
    )
}


export default Section9;