import React, { useEffect } from "react";
import "../css/section5.css"
import "../css/animations/animation5.css"
import libro2 from "../assets/PAGINA 1/LIBRO.png"
import AOS from "aos";
import 'aos/dist/aos.css';

import Button from '@mui/material/Button';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from "../components/section6.jsx";
import { sanar } from "../components/section6.jsx";

import { Container, Row, Col } from "react-bootstrap";

const Section5 = ()=>{
  useEffect(() => {
    AOS.init({duration: 2000, disable: "mobile"})
  }, []);

    return (
      <Container fluid className="fondo5">
        <hr className="horizontal5"/>
        <Row className="container-text5">
          <Col xl={{span: 7}} xs={12}>
            <Row>
              <Col xs={12} data-aos="zoom-out">
                <h3 className="title5">Mi historia merece ser contada.</h3>
              </Col>
            </Row>
            <Row>
              <Col xs={12} data-aos="zoom-out-right">
                <p className="texto5"> Un día todo cambió, parecía que estaba presa, 
                    sin salida, todo se percibía en contra...
                    Soy paciente de cáncer y metástasis, 
                    ser paciente es muy complejo, vives pensando en la muerte... 
                    Y al poco tiempo de ser diagnosticada, aún muy delicada, 
                    diagnosticaron a mi esposo también con cáncer. 
                    Parecía que el cáncer se había apoderado de mi familia al mismo tiempo. 
                    Por ello decidí escribir un libro contando cómo fue mi 
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs={12} data-aos="fade-up-right">
                <h3 className="quimio"><strong>QUIMIOTERAPIA MENTAL</strong></h3>
              </Col>
            </Row>
            <Row>
              <Col xs={12} data-aos="fade-right">
                <p className="texto5-1"> Sé cómo se vive como paciente de cáncer, 
                    y cómo puedes ser parte del equipo que todo paciente 
                    oncológico necesita.
                </p>
              </Col>
            </Row>                  
          </Col>
          <Col xl={5} xs={12} data-aos="fade-down-left" className="container-img-libro5">
            <img className="libro5" src={libro2} alt="Imagen Libro"/>
            <ThemeProvider theme={theme}>
              <a className="buy-book" target="_blank" rel="noreferrer" href="https://docs.google.com/forms/d/e/1FAIpQLSctjLIGM0xyB_92wZICXEDeScIHAhdHE8mV6C49NOl4ZMIvUA/viewform"><Button className="btn8" color="neutral" variant="contained" sx={sanar}>QUIERO EL LIBRO</Button></a>
            </ThemeProvider>
          </Col>
        </Row>
        <div className="custom-shape-divider-bottom-section5">
          <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
              <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
          </svg>
        </div>
      </Container>
    )
}


export default Section5;